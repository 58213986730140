import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import colors from 'style/imgs/branding/colors.svg';
import horizontal from 'style/imgs/branding/horizontal.svg';
import vertical from 'style/imgs/branding/vertical.svg';
import white from 'style/imgs/branding/white.svg';
import full from 'style/imgs/branding/full.svg';

import classNames from './logo.module.scss';

export const LOGO_FILES = {
  colors,
  horizontal,
  vertical,
  white,
  full,
};

export const LOGO_MAP = {
  colors: 'colors',
  horizontal: 'horizontal',
  vertical: 'vertical',
  white: 'white',
  full: 'full',
};

export default class Logo extends React.PureComponent {
  static propTypes = {
    name: PropTypes.oneOf(Object.keys(LOGO_FILES)),
    className: PropTypes.string,
  };

  static defaultProps = {
    name: LOGO_MAP.colors,
    className: '',
  };

  /** @returns {object} JSX. */
  render() {
    const {
      name,
      ...props
    } = this.props;

    props.className = cn(props.className, classNames.logo);

    const element = LOGO_FILES[name];

    if (!element) {
      return null;
    }

    return React.createElement(element, props);
  }
}
